<template>
  <AppMainContainer
    center-class="offer"
  >
    <template #subheader>
      <PageHeader isAdmin />
    </template>
    <template #center>
      <h1 class="mb-3">
        Документы
      </h1>
      <template v-if="!loader && documents">
        <div
          class="row"
        >
          <div class="col-9">
            <h4 class="mb-5">
              Документы для ознакомления
            </h4>
            <div class="documents mb-5">
              <a
                v-for="it in documents.documentList"
                :key="it.id"
                :href="it.path"
                target="_blank"
                class="documents__item"
              >
                <IconFileDoc class="mb-2" />
                <span>{{ it.name }}</span>
              </a>
            </div>
          </div>
          <div class="col-3">
            <h4 class="mb-5">
              {{ ' ' }}
            </h4>
            <div class=" flex-column">
              <a
                v-for="it in documents.zipList"
                :key="it.id"
                :href="it.path"
                target="_blank"
                class="documents__item"
              >
                <IconFileDoc class="mb-2" />
                <span style="font-size: 1.2em">{{ it.name }}</span>
              </a>
            </div>
          </div>
        </div>
        <template v-if="canAcceptTerms">
          <div style="border: 2px solid transparent; width: 100%; margin: 0 auto; text-align: center">
            <div
              class="doc__text-confirm"
            >
              Нажимая «‎Согласится с оффертой»
              <br>
              подтверждаете согласие с нашими условиями
            </div>

            <div class="doc__button-wrapper">
              <AppLoader
                v-if="isLoading"
                style="margin: 10px"
              />
              <AppButton
                v-if="!isLoading && currentUser"
                style="margin: 0 auto"
                @click.native="confirm()"
              >
                Согласиться с оффертой
              </AppButton>
            </div>
          </div>
        </template>
      </template>
      <AppLoader
        v-else
        class="full-height"
      />
    </template>
  </AppMainContainer>
</template>

<script>

import AppMainContainer from '@/components/common/layout/AppMainContainer'
import IconFileDoc from '@/components/common/icons/IconFileDoc'
import AppLoader from '@/components/AppLoader'
import userUtil from '@/utils/user-util'
import { mapActions, mapState } from 'vuex'
import AppButton from '@/components/common/simple/AppButton'
import PageHeader from '@/components/subheaders/PageHeader.vue'

export default {
  name: 'DocumentList',
  components: {
    PageHeader,
    AppMainContainer,
    AppButton,
    IconFileDoc,
    AppLoader
  },
  data: () => ({
    isLoading: false,
    documents: null,
  }),
  computed: {
    ...mapState('companyProfile', {
      loader: 'loader',
    }),
    currentUser () {
      return this.$store.state.users.info
    },
    currentOrganization () {
      return this.$store.state.organization.organizationUser
    },
    canAcceptTerms () {
      const hasAcceptTerms = this.currentOrganization && this.currentOrganization.hasAcceptTermsAndConditions
      return (this.currentUser && !userUtil.hasAcceptTerms(this.currentUser)) && !hasAcceptTerms
    }
  },
  async created () {
    const { data } = await this.fetchDocuments()
    this.documents = data
  },
  methods: {
    ...mapActions({
      fetchDocuments: 'companyProfile/fetchDocuments',
    }),
    async confirm () {
      try {
        this.isLoading = true
        await this.$store.dispatch('users/acceptTermsAndCondition')
        await this.$store.dispatch('organization/fetchOrganizationUser')
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
  }
}
</script>

<style lang="sass" scoped>

  .documents
    display: flex
    flex-wrap: wrap

    &__item
      display: flex
      flex-direction: column
      margin-bottom: 20px
      align-items: center
      min-width: 130px
      text-align: center
      margin-right: 30px
      color: rgba(0, 0, 0, 0.8)
      font-size: 10px
      text-decoration: none
      cursor: pointer

      &--zip
        align-items: start

  .doc__text-confirm
    @include fontStyle(500, 14px)
    text-align: center
    width: 50%
    margin: 50px auto 30px auto

  .doc__button-wrapper
    display: flex
</style>
